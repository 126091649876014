import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem, Select } from '@mui/material';
import AdminInvestmentProjectService from '../../services/admin-investment-project';
import AdminHubButtonService from '../../services/admin-hub-button-service';
import { Link } from 'react-router-dom';

export const DialogAddHubButton = ({ active, isInvestmentProject, projectId, currentButtonId, onCancel, onSuccess }) => {

  const adminInvestmentProjectService = new AdminInvestmentProjectService();
  const adminHubButtonService = new AdminHubButtonService();

  const [buttons, setButtons] = useState();
  const [selectedButtonId, setSelectedButtonId] = useState();


  useEffect(() => {
    adminHubButtonService.getAllHubButtons()
      .then(response => {
        setButtons(response.data);

        if (currentButtonId) {
          setSelectedButtonId(currentButtonId);
        }
      });
  }, [active]);

  // Set the required event data.
  const handleSubmit = () => {
    if (isInvestmentProject) {
      adminInvestmentProjectService.handleHubButton(projectId, selectedButtonId)
        .then(() => {
          onSuccess();
        });
    }
  };

  const handleRemoveButton = () => {
    adminInvestmentProjectService.handleHubButton(projectId, undefined)
      .then(() => {
        onSuccess();
      });
  };


  return (<>
    {projectId && buttons?.length && <Dialog open={active}>
      <DialogTitle sx={{ pb: '5px' }}>Add button</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Select a button to add to this project, or add an new button<a href={'/admin/configuration/branding'}> here.</a>
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >

          <FormControl size="small" >
            <InputLabel id="button-label">Select button</InputLabel>
            <Select
              labelId="button-label"
              value={selectedButtonId || ''}
              label="Select button"
              onChange={e => setSelectedButtonId(e.target.value)}
              sx={{ minWidth: '200px' }}
            >

              {buttons.map((button, index) => {
                return <MenuItem value={button.id}>{`${button.title_copy} (${button.url})`}</MenuItem>;
              })}
            </Select>
          </FormControl>
          {currentButtonId && <Button
            variant="contained"
            color="error"
            sx={{ ml: 2 }}
            disableElevation
            onClick={handleRemoveButton}
            className="removeUppercase"
          >
            Remove button
          </Button>}

        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={onCancel} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          className="removeUppercase"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>}
  </>
  );
};
