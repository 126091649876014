import { Card, CardContent, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { CustomerServiceTextEmailLink } from '../../shared/components/presentation/customer-service-text-email-link';

export const ThirdPartyConnectionsCard = ({ isActive, title, image, description, link }) => {



  return (
    <>
      {!isActive && <Card sx={{
        height: '100%', boxShadow: 'none', borderWidth: '1px', borderStyle: 'solid', borderColor: 'lightGrey.main', borderRadius: '10px', pt: 2, pr: 2, pl: 2,
      }}>
        <Link to={link} style={{ textDecoration: 'none' }}>
          <CardContent sx={{ p: 0 }}>
            <Stack justifyContent='center' alignItems='center'>
              <Stack sx={{ height: '150px', justifyContent: 'center', alignItems: 'center' }}>
                <img src={image} alt={title} width={'150rem'} />
              </Stack>
              <Stack sx={{ pt: 2, pl: 2, pr: 2 }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 600, color: 'text.primary', pb: 1 }}>
                  {title}
                </Typography>
                {description && <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  {description}
                </Typography>}
              </Stack>
            </Stack>
          </CardContent>
        </Link>
      </Card>}

      {isActive && <Card sx={{
        height: '100%', boxShadow: 'none', borderWidth: '1px', borderStyle: 'solid', borderColor: 'lightGrey.main', borderRadius: '10px', pt: 2, pr: 2, pl: 2,
      }}>
        <CardContent sx={{ p: 0 }}>
          <Stack justifyContent='center' alignItems='center'>
            <Stack sx={{ height: '150px', justifyContent: 'center', alignItems: 'center' }}>
              <img src={image} alt={title} width={'150rem'} />
            </Stack>
            <Stack sx={{ pt: 2, pl: 2, pr: 2 }}>
              <Typography variant='subtitle1' sx={{ fontWeight: 600, color: 'text.primary', pb: 1 }}>
                {title}
              </Typography>
              {description && <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                Your account is connected. Any new enquiries will be logged in your Hubspot account. If you have any questions please contact <CustomerServiceTextEmailLink />.
              </Typography>}
            </Stack>
          </Stack>
        </CardContent>
      </Card>}
    </>
  );
};