import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import InvestmentProjectService from '../../services/investment-project-service';
import InvestmentProjectCard from './investment-project-card';
import gaiaTheme from '../../../theme';


export const RelatedProjectDisplay = ({ isInvestmentProject, currentProjectId }) => {

  const investmentProjectService = new InvestmentProjectService();
  const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('md'));

  const [investmentProjects, setInvestmentProjects] = useState();

  useEffect(() => {
    if (isInvestmentProject) {
      investmentProjectService.getRelatedInvestmentProjects(currentProjectId)
        .then(response => {
          setInvestmentProjects(response);
        });
    }
  }, []);


  return (
    <Paper sx={{ padding: 2 }} elevation={5}>

      <Typography variant="h3" color="text.secondary" sx={{ mb: 3 }}>
        Related projects
      </Typography>

      <Stack direction={isMobileMode ? 'column' : 'row'} justifyContent={'space-between'}>
        {investmentProjects && investmentProjects.map((project, index) => {
          return <Box sx={{ maxWidth: isMobileMode ? '100%' : '32%', mb: 2 }} key={index}>
            <InvestmentProjectCard
              id={project.id}
              title_text={project.title_text}
              subtitle_text={project.subtitle_text}
              description={project.description}
              primaryImageURL={project?.investment_project_images[0]?.location}
              shallow_status={project.shallow_status}
              un_goals={project.un_goals}
              investment_target={project.investment_target}
              externalProjectUrl={project.external_project_url}
              amountPledged={project.amount_pledged}
              comingSoon={project.coming_soon}
            ></InvestmentProjectCard>

          </Box>;

        })}
      </Stack>
    </Paper>
  );

};