import { Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import EnquiryService from '../../services/enquiry-service';
import { UserInformationProviderContext } from '../../context/user-information-provider';
import { HubDetailsContext } from '../../context/hub-details';
import { PrivacyPolicyLink } from '../presentation/privacy-policy-link';


export const DialogUnitProjectEnquiry = ({ active, projectId, onSubmit, onCancel }) => {

  const userInformationContext = useContext(UserInformationProviderContext);
  const hubDetailsContext = useContext(HubDetailsContext);

  const enquiryService = new EnquiryService();
  const [hasError, setHasError] = useState(false);
  const [details, setDetails] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [privacyPolicyText, setPrivacyPolicyText] = useState();
  const [privacyPolicyConfirmed, setPrivacyPolicyConfirmed] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (hubDetailsContext?.hubDetails?.organisation_settings?.enquiry_process_privacy_text) {
      setPrivacyPolicyText(hubDetailsContext?.hubDetails?.organisation_settings?.enquiry_process_privacy_text);
    }
  }, active);

  const handleDetailsChange = (event) => {
    setDetails({ ...details, [event.target.name]: event.target.value });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    setHasError(false);
    setFormSubmitted(true);
    if (details.name && details.email && details.message && (!privacyPolicyText || privacyPolicyConfirmed)) {
      enquiryService.sendUnitProjectEnquiry(details, projectId)
        .then(() => {
          userInformationContext.openSnackbar({ open: true, message: 'Enquiry sent successfully.', backgroundColor: 'green', duration: 10000 });

          onSubmit();
        });
    } else {
      setHasError(true);
    }

  };

  return (
    <Dialog maxWidth='sm' fullWidth open={active}>
      <DialogTitle sx={{ p: 8, pb: 4 }}>
        <Typography variant="h5">
          Contact us for more information.
        </Typography>
        <Divider sx={{ mt: 4 }} />
      </DialogTitle>
      <DialogContent>

        <Box sx={{ padding: 2 }}>
          {hasError && <Alert sx={{ mb: 2 }} severity='error'>Please enter all the required information below.</Alert>}

          <FormControl sx={{ mb: 1, width: '100%' }}>
            <TextField
              className="formField"
              label="Name"
              error={formSubmitted && !details.name}
              name='name'
              value={details.name}
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl>
          {/* <FormControl sx={{ mb: 1, width: '100%' }}>
            <TextField
              className="formField"
              label="Organisation"
              error={formSubmitted && !details.organisation}
              name='organisation'
              value={details.organisation}
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl> */}
          <FormControl sx={{ mb: 1, width: '100%' }}>
            <TextField
              className="formField"
              label="Email"
              error={formSubmitted && !details.email}
              name='email'
              value={details.email}
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl>
          {/* <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
            <InputLabel htmlFor="total-price">Amount</InputLabel>
            <OutlinedInput
              required={true}
              inputProps={{ min: 0 }}
              startAdornment={<InputAdornment position="start">£</InputAdornment>}

              value={details.amount}
              label="Amount"
              name='amount'
              placeholder="Amount looking to fund."
              type="number"
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl> */}
          <FormControl sx={{ mb: 2, width: '100%' }}>
            <TextField
              label="You message"
              minRows={5}
              multiline={true}
              error={formSubmitted && !details.message}
              name='message'
              value={details.message}
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl>

          {privacyPolicyText &&
            <FormControlLabel
              sx={{ mb: 2 }}
              control={<Checkbox
                error={formSubmitted && !privacyPolicyConfirmed}
                name='resource_is_external'
                checked={privacyPolicyConfirmed || false}
                onChange={e => setPrivacyPolicyConfirmed(e.target.checked)}
              />}
              label={<PrivacyPolicyLink wrappingText={privacyPolicyText}></PrivacyPolicyLink>}>
            </FormControlLabel>}
        </Box>
      </DialogContent>

      <DialogActions>
        <Stack direction='row' pb={2} pr={2} spacing={2}>
          <Button onClick={() => handleCancel()} className="removeUppercase">Cancel</Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleConfirm()}
            className="removeUppercase"
          >
            Send
          </Button>
        </Stack>
      </DialogActions>
    </Dialog >
  );
}


