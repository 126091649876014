import { Container, Grid, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from '../../../shared/context/user-details';
import UserService from '../../../shared/services/user-service';
import { UserInformationProviderContext } from '../../../shared/context/user-information-provider';
import ThirdPartyConnectionService from '../../services/third-party-connection.service';
import { ThirdPartyConnectionsCard } from '../../components/third-party-connection-card';
import HubSpotLogo from '../../../assets/images/HubSpot_Logo.png';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const ThirdPartyConnectionLandingPage = () => {

  const userDetailsContext = useContext(UserDetailsContext);
  const userInformationContext = useContext(UserInformationProviderContext);
  const thirdPartyConnectionService = new ThirdPartyConnectionService();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.providerCode) {
      console.log(params.providerCode);
      if (searchParams.has('code')) {
        const code = searchParams.get('code');
        console.log(code);
        thirdPartyConnectionService.submitAuthCode(params.providerCode, code)
          .then(() => {
            navigate('/settings/third-party-connection');
          });
      }
    }
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            minHeight: '100%',
            borderRadius: '10px'
          }}
          variant='outlined'
        >

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h5" color="text.primary" gutterBottom sx={{ mb: 1 }}>
              Please wait...
            </Typography>
          </Stack>
        </Paper>
      </Container>
    </>
  );
};