import httpClient from '../http/http-client';

export default class EnquiryService {
  http;

  constructor() {
    this.http = httpClient;
  }

  async submitNewEnquiry(enquiry) {
    return this.http.post(`buyer/enquiry`, enquiry)
      .then(response => {
        return response.data;
      });
  }

  async sendNewMessage(enquiryId, userType, message) {
    return this.http.post(`${userType}/enquiry/${enquiryId}/message`, { message: message })
      .then(response => {
        return response.data;
      });
  }

  async sendInvestmentProjectEnquiry(details, investmentProjectId) {
    return this.http.post(`buyer/enquiry/investment-project/${investmentProjectId}/message`, { details: details })
      .then(response => {
        return response.data;
      });
  }

  async sendUnitProjectEnquiry(details, projectId) {
    return this.http.post(`buyer/enquiry/unit-project/${projectId}/message`, { details: details })
      .then(response => {
        return response.data;
      });
  }


}