import { Autocomplete, Button, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Edit, Save } from '@mui/icons-material';
import AdminProjectService from '../../../admin/services/admin-project-service';
import { CustomTooltip } from '../presentation/tooltip';

export const CustomProjectIconDisplay = ({ isAdmin, project, getProject }) => {

  const adminProjectService = new AdminProjectService();

  const [isEditing, setIsEditing] = useState(false);
  const [allIcons, setAllIcons] = useState([]);
  const [selectedIcons, setSelectedIcons] = useState();

  const toggleEdit = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      const icons = selectedIcons.map(icon => icon.id);

      adminProjectService.setProjectIcons(project.p_id, { icon_ids: icons })
        .then(() => {
          setIsEditing(false);
          getProject();
        });
    }
  };

  const handleUpdateGoals = (icons) => {
    setSelectedIcons(icons);
  };

  useEffect(() => {
    adminProjectService.getAllCustomProjectIcons()
      .then(response => {
        setAllIcons(response);
      });

    setSelectedIcons(project.custom_project_icons);

  }, [project]);

  return (<>

    {isAdmin && <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 2 }}>
      <Typography variant="h6" color="text.primary">
        <b>Conservation area characteristics:</b>
      </Typography>
      <Button
        variant={isEditing ? 'contained' : 'outlined'}
        color={isEditing ? 'primary' : 'info'}
        size="large"
        disableElevation
        className='removeUppercase'
        startIcon={isEditing ? <Save /> : <Edit />}
        onClick={() => toggleEdit()}
      //disabled={!canSendEnquiry}
      >
        {isEditing ? 'Save icons' : 'Edit'}
      </Button>
    </Stack>}
    {isEditing && allIcons?.length > 0 && selectedIcons && <FormControl fullWidth margin='normal' sx={{ mb: 4 }}>
      <Autocomplete
        disablePortal
        id="icons"
        value={selectedIcons}
        options={allIcons}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Available Icons" />}
        multiple
        getOptionLabel={(option) => (option ? option.copy : "")}
        onChange={(e, option, reason) => handleUpdateGoals(option)}
      />
    </FormControl>}

    {selectedIcons?.length === 0 && !isEditing && <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
      <b><i>No custom icons added</i></b>
    </Typography>}
    {selectedIcons?.length > 0 && <>
      <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 1 }}>
        {selectedIcons.length > 0 && selectedIcons?.map((icon, index) => {
          return <Grid item xs={6} md={3} lg={2} key={index}>
            <CustomTooltip content={icon.copy}>
              <img key={index} alt='Custom Icon' src={icon.image_url} style={{ width: '100%' }} />
            </CustomTooltip>

          </Grid>;
        })}
      </Stack>
    </>
    }
  </>);

};