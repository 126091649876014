import { useContext, useState } from 'react';
import { HubDetailsContext } from '../../context/hub-details';
import { Box, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Toolbar, Typography } from '@mui/material';
import { UserInformationProviderContext } from '../../context/user-information-provider';
import { AnalyticsDashboardTile } from './analytics-dashboard-tile';

export const AnalyticsPage = () => {

  const hubContext = useContext(HubDetailsContext);
  const infoDialog = useContext(UserInformationProviderContext);

  const [searchYear, setSearchYear] = useState();
  const [searchMonth, setSearchMonth] = useState();



  const handleSearchMonth = (value) => {
    if (!searchYear) {
      setSearchYear(2025);
    }
    setSearchMonth(value);
  };

  const handleSearchYear = (value) => {
    setSearchYear(value);
  };

  // const submitForm = () => {
  //   setError(false);
  //   if (!details.name || !details.email || !details.message) {
  //     setError(true);
  //   } else {
  //     contactUsService.submitForm(details)
  //       .then(() => {
  //         //infoDialog.openSnackbar({ open: true, message: 'Message sent successfully.', backgroundColor: 'green' });
  //         infoDialog.openDialog({
  //           active: true,
  //           title: 'Thank you for your message',
  //           message: 'Thanks for getting in contact, one of our team will be in touch with you soon.',
  //           hideCancelButton: true,
  //           buttonCopy: 'Ok'
  //         });
  //         setDetails({ name: '', email: '', message: '' });
  //       });
  //   }
  // };

  return (
    <>
      <Toolbar />
      <Box
        sx={{
          height: '45vh',
          mt: 2,
          backgroundImage: `url(${hubContext.hubDetails.hub_styles?.banner_image_url || '/hub_banner_1.jpg'})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
            <Grid item xs={12} md={7} lg={7}>
              <Typography
                variant="h3"
                component="h1"
                sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
              >
                BNG Data Studio
              </Typography>
              <Typography
                variant="h6"
                sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
              >
                Use the page below to view BNG unit analytics.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ pt: 8, pb: 8 }}>

        {/* <ToggleButtonGroup
          value={groupBy}
          exclusive
          onChange={handleGroupBy}
          orientation={'horizontal'}
        >
          <ToggleButton value="region" sx={{ width: '200px', pt: 1, pb: 1, textTransform: 'none' }}>
            <Typography>Region</Typography>
          </ToggleButton>
          <ToggleButton value="lpa" sx={{
            width: '100%', p: 1, textTransform: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            textAlign: 'center'

          }}>
            <Typography>Local planning authority</Typography>
          </ToggleButton>
          <ToggleButton value="unit_type" sx={{ width: '200px', pt: 1, pb: 1, textTransform: 'none' }}>
            <Typography>Unit type</Typography>
          </ToggleButton>
        </ToggleButtonGroup> */}


        <Stack direction={'row'} justifyContent={'space-between'} sx={{ borderRadius: '10px', padding: 2 }}>
          <Typography
            variant="h6"
            sx={{ mb: 2 }}
          >
            Popularity breakdown
          </Typography>

          <Stack direction={'row'} justifyContent={'space-between'}>
            <FormControl size="small" sx={{ width: '150px', mr: 1 }}>
              <InputLabel id="user-type-label">Month</InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type"
                value={searchMonth || ''}
                label="Month"
                onChange={(e) => handleSearchMonth(e.target.value)}
              >
                <MenuItem value={'01'}>January</MenuItem>
                <MenuItem value={'02'}>February</MenuItem>
                <MenuItem value={'03'}>March</MenuItem>
                <MenuItem value={'04'}>April</MenuItem>
                <MenuItem value={'05'}>May</MenuItem>
                <MenuItem value={'06'}>June</MenuItem>
                <MenuItem value={'07'}>July</MenuItem>
                <MenuItem value={'08'}>August</MenuItem>
                <MenuItem value={'09'}>September</MenuItem>
                <MenuItem value={'10'}>October</MenuItem>
                <MenuItem value={'11'}>November</MenuItem>
                <MenuItem value={'12'}>December</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: '150px' }}>
              <InputLabel id="user-type-label">Year</InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type"
                value={searchYear || ''}
                label="Year"
                onChange={(e) => handleSearchYear(e.target.value)}
              >
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <AnalyticsDashboardTile
            title={'Most Popular Regions'}
            groupBy={'region'}
            isSearchAnalytics={true}
            customValueString={'Total'}
            searchMonth={searchMonth}
            searchYear={searchYear}
            valueFormatStringValue={'%'}
            allowDownload={true}
          ></AnalyticsDashboardTile>

          <AnalyticsDashboardTile
            title={'Most Popular Unit Type\'s'}
            groupBy={'unit_type'}
            isSearchAnalytics={true}
            customValueString={'Total'}
            searchMonth={searchMonth}
            searchYear={searchYear}
            valueFormatStringValue={'%'}
            useDonutChart={true}
            allowDownload={true}
          ></AnalyticsDashboardTile>
        </Stack>

        <AnalyticsDashboardTile
          title={'Most popular Local Planning Authorities (Top 10)'}
          groupBy={'lpa'}
          isSearchAnalytics={true}
          customValueString={'Total'}
          searchMonth={searchMonth}
          searchYear={searchYear}
          valueFormatStringValue={'%'}
          allowDownload={true}
        ></AnalyticsDashboardTile>

        <Divider sx={{ mt: 4, mb: 4 }}></Divider>

        <Stack direction={'row'} justifyContent={'space-between'} sx={{ borderRadius: '10px', padding: 2 }}>
          <Typography
            variant="h6"
            sx={{ mb: 2 }}
          >
            Availability breakdown
          </Typography>

          <Stack direction={'row'} justifyContent={'space-between'}>
            <FormControl size="small" sx={{ width: '150px', mr: 1 }}>
              <InputLabel id="user-type-label">Month</InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type"
                value={'01'}
                disabled={true}
                label="Month"
                onChange={(e) => handleSearchMonth(e.target.value)}
              >
                <MenuItem value={'01'}>January</MenuItem>
                <MenuItem value={'02'}>February</MenuItem>
                <MenuItem value={'03'}>March</MenuItem>
                <MenuItem value={'04'}>April</MenuItem>
                <MenuItem value={'05'}>May</MenuItem>
                <MenuItem value={'06'}>June</MenuItem>
                <MenuItem value={'07'}>July</MenuItem>
                <MenuItem value={'08'}>August</MenuItem>
                <MenuItem value={'09'}>September</MenuItem>
                <MenuItem value={'10'}>October</MenuItem>
                <MenuItem value={'11'}>November</MenuItem>
                <MenuItem value={'12'}>December</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: '150px' }}>
              <InputLabel id="user-type-label">Year</InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type"
                value={2025}
                disabled={true}
                label="Year"
                onChange={(e) => handleSearchYear(e.target.value)}
              >
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <AnalyticsDashboardTile
            title={'Unit availability by Region'}
            groupBy={'region'}
            customValueString={'Total'}
            valueFormatStringValue={'%'}
            available={true}
            allowDownload={true}
          ></AnalyticsDashboardTile>

          <AnalyticsDashboardTile
            title={'Unit availability by Unit Type'}
            groupBy={'unit_type'}
            customValueString={'Total'}
            available={true}
            valueFormatStringValue={'%'}
            useDonutChart={true}
            allowDownload={true}
          ></AnalyticsDashboardTile>
        </Stack>

        {/* <AnalyticsDashboardTile
          title={'Unit availability by Local Planning Authority'}
          groupBy={'lpa'}
          customValueString={'Total'}
          available={true}
          valueFormatStringValue={'%'}
        ></AnalyticsDashboardTile> */}

        <Divider sx={{ mt: 4, mb: 4 }}></Divider>

        <Stack direction={'row'} justifyContent={'space-between'} sx={{ borderRadius: '10px', padding: 2 }}>
          <Typography
            variant="h6"
            sx={{ mb: 2 }}
          >
            Average pricing information
          </Typography>

          <Stack direction={'row'} justifyContent={'space-between'}>
            <FormControl size="small" sx={{ width: '150px', mr: 1 }}>
              <InputLabel id="user-type-label">Month</InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type"
                value={'01'}
                disabled={true}
                label="Month"
                onChange={(e) => handleSearchMonth(e.target.value)}
              >
                <MenuItem value={'01'}>January</MenuItem>
                <MenuItem value={'02'}>February</MenuItem>
                <MenuItem value={'03'}>March</MenuItem>
                <MenuItem value={'04'}>April</MenuItem>
                <MenuItem value={'05'}>May</MenuItem>
                <MenuItem value={'06'}>June</MenuItem>
                <MenuItem value={'07'}>July</MenuItem>
                <MenuItem value={'08'}>August</MenuItem>
                <MenuItem value={'09'}>September</MenuItem>
                <MenuItem value={'10'}>October</MenuItem>
                <MenuItem value={'11'}>November</MenuItem>
                <MenuItem value={'12'}>December</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: '150px' }}>
              <InputLabel id="user-type-label">Year</InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type"
                value={2025}
                disabled={true}
                label="Year"
                onChange={(e) => handleSearchYear(e.target.value)}
              >
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <AnalyticsDashboardTile
            title={'Average Unit Price by Region'}
            groupBy={'region'}
            isSearchAnalytics={false}
            customValueString={'Average unit price (£)'}
            allowDownload={true}
          ></AnalyticsDashboardTile>

          <AnalyticsDashboardTile
            title={'Average Unit Price by Unit Type'}
            groupBy={'unit_type'}
            isSearchAnalytics={false}
            customValueString={'Average unit price (£)'}
            allowDownload={true}
          ></AnalyticsDashboardTile>
        </Stack>

        <AnalyticsDashboardTile
          title={'Average Unit Price by Local Planning Authority'}
          groupBy={'lpa'}
          isSearchAnalytics={false}
          customValueString={'Average unit price (£)'}
          allowDownload={true}
        ></AnalyticsDashboardTile>
      </Container>
    </>
  );
};