import React, { useContext, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ProjectService from "../services/project-service.service";
import * as geoJsonRegionsScotland from "../data/maps/scotland-regions.json";
import * as geojsonData from "../data/maps/regions-uk";
import * as lpaData from "../data/maps/local-planning-authorities";
// import * as geojsonData from "../data/maps/national-character-areas";
import * as turf from "@turf/turf"; // Import turf.js for calculating bounding box
import { Link, useNavigate } from 'react-router-dom';
import { HubDetailsContext } from '../context/hub-details';
import { Box } from '@mui/material';

const styledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "transparent",
    margin: 0,
    padding: 0,
    whiteSpace: "pre-line",
  },
}));

export default function ProjectMapEngland(props) {
  const mapContainer = useRef(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const projectService = new ProjectService();
  const hubDetailsContext = useContext(HubDetailsContext);
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    projectService.getGeoLocations()
      .then(response => {
        console.log(response);
        const localMarkers = response.map(marker => {
          return {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [parseFloat(marker.longitude), parseFloat(marker.latitude)]
            },
            'properties': {
              'title': marker.p_summary,
              'description': 'Project location',
              "marker-symbol": "default_marker"

            },
          };
        });

        setMarkers(localMarkers);
      });
  }, []);

  useEffect(() => {
    // mapboxgl.accessToken = 'pk.eyJ1IjoiZ2FpYXRlY2hub2xvZ2llcyIsImEiOiJjbTBmZHdzMHUwdTBsMmtzMWUzeDI4dDJ5In0.urhWTkJYeGEhMlsA-FCf1A';
    mapboxgl.accessToken = 'pk.eyJ1IjoicGV0ZWdhaWEiLCJhIjoiY201bngzajdiMGVwdzJrczYyN3JzeDlidyJ9.jUk5MwhcVqxnTiIF9T4TNw';

    let center;
    let bounds;
    let zoom = 5.8;
    let dragPan = true;
    let interactive = true;

    if (hubDetailsContext?.hubDetails?.organisation_settings?.include_england_lpas) {
      center = [-1.5, 53.0];
    } else {
      center = [-4.289304, 57.056531];

      bounds = [
        [-14.311532, 54.023726], // Southwest coordinates
        [6.494418, 61.698851] // Northeast coordinates
      ];

      dragPan = false;
      interactive = false;

      // style = 'mapbox://styles/petegaia/cm5o1a7g600eo01s3baub7qla';
      zoom = 6.03;
    }

    let style = 'mapbox://styles/mapbox/outdoors-v12';
    if (hubDetailsContext.hubDetails?.organisation_settings?.custom_map_url) {
      style = hubDetailsContext.hubDetails?.organisation_settings?.custom_map_url;
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: style,
      center: center,
      zoom: zoom,
      maxBounds: bounds,
      dragPan: dragPan,
      interactive: interactive
    });

    // const map = new mapboxgl.Map({
    //   container: mapContainer.current,
    //   style: "mapbox://styles/petegaia/cm5o1a7g600eo01s3baub7qla",
    //   center: center,
    //   // dragPan: false,
    //   zoom: 5.45,
    //   maxBounds: bounds
    // });



    mapRef.current = map;
    let hoveredPolygonId = null;
    let hoveredPolygonId2 = null;

    map.on("load", () => {
      if (hubDetailsContext?.hubDetails?.organisation_settings?.include_england_lpas) {
        map.addSource("regions", {
          type: "geojson",
          data: geojsonData,
        });

        // Add a fill layer for the regions
        map.addLayer({
          id: "regions-fill",
          type: "fill",
          source: "regions",
          paint: {
            "fill-color": "#5c8024",
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              0.5,
              0.3
            ]
          },
        });

        // Add an outline layer for the regions
        map.addLayer({
          id: "regions-outline",
          type: "line",
          source: "regions",
          paint: {
            "line-color": "#364D0B",
            "line-width": 1,
          },
        });

        map.on("click", "regions-fill", (e) => {
          const clickedRegion = e.features[0].properties.RGN20NM;
          let regionName;
          if (clickedRegion === "London" || clickedRegion === "South East") {
            regionName = "South East and London";
          } else if (clickedRegion === "Yorkshire and The Humber") {
            regionName = "Yorkshire and the Humber";
          } else {
            regionName = clickedRegion;
          }

          // Set the selected region
          setSelectedRegion(regionName);

          // props.handleMapRegionClick(regionName);
          navigate(`/hub/marketplace?region=${regionName}&show_neighbour_lpas=true&show_neighbour_ncas=true`);
          navigate(0);

          // Get the bounding box of the clicked region using turf.js
          const bounds = turf.bbox(e.features[0].geometry);
          const sw = [bounds[0], bounds[1]]; // Southwest corner [lon, lat]
          const ne = [bounds[2], bounds[3]]; // Northeast corner [lon, lat]

          // Zoom and fit bounds to the clicked region
          map.fitBounds([sw, ne], {
            padding: 200, // Optional padding
            duration: 1000, // Animation duration (in ms)
          });
        });

        // Change the cursor to pointer when hovering over regions
        map.on("mouseenter", "regions-fill", (e) => {
          map.getCanvas().style.cursor = "pointer";
          let regionName = e.features[0].properties.RGN20NM;

          if (!regionName) {
            regionName = e.features[0].properties.LAD13NM;
          }
          console.log(regionName);
        });

        map.on("mouseleave", "regions-fill", () => {
          map.getCanvas().style.cursor = "";
        });

        map.on('mousemove', 'regions-fill', (e) => {
          if (e.features.length > 0) {
            if (hoveredPolygonId !== null && hoveredPolygonId !== undefined) {
              map.setFeatureState(
                { source: 'regions', id: hoveredPolygonId },
                { hover: false }
              );
            }
            hoveredPolygonId = e.features[0].id;
            map.setFeatureState(
              { source: 'regions', id: hoveredPolygonId },
              { hover: true }
            );
          }
        });

      }

      if (hubDetailsContext?.hubDetails?.organisation_settings?.include_scotland_lpas) {
        map.addSource("regions2", {
          type: "geojson",
          data: geoJsonRegionsScotland,
        });

        map.addLayer({
          id: "regions-fill2",
          type: "fill",
          source: "regions2",
          paint: {
            "fill-color": "#5c8024",
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              0.8,
              0.5
            ]
          },
        });

        map.addLayer({
          id: "regions-outline2",
          type: "line",
          source: "regions2",
          paint: {
            "line-color": "#364D0B",
            "line-width": 1,
          },
        });

        var popup = new mapboxgl.Popup({
          offset: [0, -7],
          closeButton: false,
          closeOnClick: false
        });

        map.on("mouseenter", "regions-fill2", (e) => {
          map.getCanvas().style.cursor = "pointer";
          let regionName = e.features[0].properties.RGN20NM;

          if (!regionName) {
            regionName = e.features[0].properties.LAD13NM;
          }

        });

        map.on("mouseleave", "regions-fill2", (e) => {
          map.getCanvas().style.cursor = "";
        });

        map.on('mouseenter', 'water', (e) => {
          popup.remove();
        });

        map.on('mousemove', 'regions-fill2', (e) => {
          if (e.features.length > 0) {
            if (hoveredPolygonId2 !== null && hoveredPolygonId2 !== undefined) {
              map.setFeatureState(
                { source: 'regions2', id: hoveredPolygonId2 },
                { hover: false }
              );
            }

            hoveredPolygonId2 = e.features[0].id;
            map.setFeatureState(
              { source: 'regions2', id: hoveredPolygonId2 },
              { hover: true }
            );


            map.getCanvas().style.cursor = "pointer";
            let regionName = e.features[0].properties.local_auth;

            if (!regionName) {
              regionName = e.features[0].properties.local_auth;
            }

            mapRef.current.getCanvas().style.cursor = 'pointer';


            let coordinates = e.features[0].geometry.coordinates.slice();

            // while (coordinates?.length !== 2) {
            //   coordinates = coordinates[0];
            // }

            let minLat = +1000;
            let maxLat = -1000;
            let minLon = +1000;
            let maxLon = -1000;


            coordinates.forEach(c => {
              while (c[0]?.length !== 2) {
                c = c[0];
              }

              c.forEach(co => {
                if (co[0] > maxLat) {
                  maxLat = co[0];
                }

                if (co[0] < minLat) {
                  minLat = co[0];
                }

                if (co[1] > maxLon) {
                  maxLon = co[1];
                }

                if (co[1] < minLon) {
                  minLon = co[1];
                }
              });
            });

            const center = [minLat + ((maxLat - minLat) / 2), minLon + ((maxLon - minLon) / 2)];
            popup.setLngLat(center).setHTML(regionName).addTo(mapRef.current);
          }
        });

        // Listen for clicks on the regions layer
        map.on("click", "regions-fill2", (e) => {
          const clickedRegion = e.features[0].properties.local_auth;

          let regionName;

          regionName = clickedRegion;


          // Set the selected region
          setSelectedRegion(regionName);

          // props.handleMapRegionClick(regionName);
          navigate(`/hub/marketplace?region=${regionName}&show_neighbour_lpas=true&show_neighbour_ncas=true`);
          navigate(0);

          // Get the bounding box of the clicked region using turf.js
          const bounds = turf.bbox(e.features[0].geometry);
          const sw = [bounds[0], bounds[1]]; // Southwest corner [lon, lat]
          const ne = [bounds[2], bounds[3]]; // Northeast corner [lon, lat]

          // Zoom and fit bounds to the clicked region
          map.fitBounds([sw, ne], {
            padding: 200, // Optional padding
            duration: 1000, // Animation duration (in ms)
          });
        });

      }

      markers.forEach(marker => {
        const popup = new mapboxgl.Popup({ offset: 25, className: 'test123' }).setText(
          marker.properties.title
        );
        new mapboxgl.Marker()
          .setLngLat(marker.geometry.coordinates)
          .setPopup(popup)
          .addTo(map);
      });



      // map.addLayer({
      //   "id": "markers",
      //   "source": "markers",
      //   "type": "symbol",

      // });

      // "text-field": "{title}",
      //   "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      //     "text-offset": [0, 0.6],
      //       "text-anchor": "top"




      // Add Subregions (Nested regions)
      // map.addSource("lpas", {
      //   type: "geojson",
      //   data: lpaData,
      // });

      // map.addLayer({
      //   id: "lpas-fill",
      //   type: "fill",
      //   source: "lpas",
      //   paint: {
      //     "fill-color": "#5c8024",
      //     "fill-opacity": 0.3,
      //   },
      //   filter: ["==", "parentRegionCode", ""], // Initially hide subregions
      // });

      // // Add an outline layer for the regions
      // map.addLayer({
      //   id: "lpas-outline",
      //   type: "line",
      //   source: "lpas",
      //   paint: {
      //     "line-color": "#364D0B",
      //     "line-width": 1,
      //   },
      //   filter: ["==", "parentRegionCode", ""], // Initially hide subregions
      // });


    });

    // Cleanup on unmount
    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [markers, hubDetailsContext.hubDetails?.id]);

  // Handling the region highlight after zooming/panning
  useEffect(() => {
    const map = mapRef.current;
    if (map && selectedRegion) {
      const lpas = props.regions.filter(region => region.value === selectedRegion)[0].lpas;
      const lpaNames = lpas.map(lpa => lpa.lpa_name);

      // map.setFilter("lpas-fill", ["in", "LPA23NM", ...lpaNames]);
      // map.setFilter("lpas-outline", ["in", "LPA23NM", ...lpaNames]);
    }
  }, [selectedRegion]); // Re-run when the selected region changes


  return (
    <Box sx={{ width: '100%', height: '858px', maxHeight: '100vw', position: 'relative' }}>

      {!hubDetailsContext?.hubDetails?.organisation_settings?.include_england_lpas && <Box sx={{
        maxHeight: '200px',
        maxWidth: "160px",
        objectFit: 'contain',
        position: 'absolute',
        right: '10%',
        top: '12%',
        zIndex: 200,
        border: '1px solid black',
        cursor: 'pointer',
      }}
        onClick={() => {
          navigate(`/hub/marketplace?region=Shetland Islands&show_neighbour_lpas=true&show_neighbour_ncas=true`);
          navigate(0);
        }}>
        <img
          src="/shetland-island.png"
          alt="logo"
          style={{
            height: '100%', width: "100%", objectFit: 'contain'
          }}
        />
      </Box>}

      <div ref={mapContainer} style={{ width: "100%", height: "100%" }} />
    </Box>

  );
}
