import { useContext, useEffect, useState } from 'react';
import { Box, Card, CardActionArea, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Toolbar, Typography } from '@mui/material';
import { HubDetailsContext } from '../../context/hub-details';
import { CalendarMonth, NewspaperRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import EventService from '../../services/event-service';
import moment from 'moment/moment';

export const NewsPage = () => {

  const hubContext = useContext(HubDetailsContext);
  const eventService = new EventService();

  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    eventService.getAllEvents()
      .then(response => {
        setNews(response.filter(item => item.event_type === 'news').sort((b, a) => new Date(a.date).getTime() - new Date(b.date).getTime()));
        setEvents(response.filter(item => item.event_type === 'event'));
      });
  }, []);

  return (<>
    <Toolbar />
    <Box
      sx={{
        height: '45vh',
        mt: 2,
        backgroundImage: `url(${hubContext.hubDetails.hub_styles?.banner_image_url || '/hub_banner_1.jpg'})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
          <Grid item xs={12} md={7} lg={7}>
            <Typography
              variant="h3"
              component="h1"
              sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
            >
              News and Events
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
            >
              All the latest news and events
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Container maxWidth='lg' sx={{ mt: 2 }}>
      {events.length > 0 && <Box sx={{ mt: 4, mb: 6 }}>
        <Typography variant='h6' sx={{ pt: 1, pb: 2 }}>
          Events:
        </Typography>
        {events.map(obj => {
          return <Card variant='outlined' sx={{ borderRadius: '10px', mt: 2, mb: 2 }} key={obj.title}>
            <CardActionArea to={obj.link_url} target={'_blank'} component={Link} sx={{ p: 2 }}>
              <List>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon>
                    <CalendarMonth />
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.title}
                    secondary={obj.display_date || moment(obj.date).format('dd/MM/yyyy')}
                  />
                </ListItem>
              </List>
            </CardActionArea>
          </Card>;
        })}
      </Box>}

      {news.length > 0 && <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant='h6' sx={{ pt: 1, pb: 2 }}>
          News and press releases:
        </Typography>
        {news.map(obj => {
          return <Card variant='outlined' sx={{ borderRadius: '10px', mt: 2, mb: 2 }} key={obj.title}>
            <CardActionArea to={obj.link_url} component={Link} target={'_blank'} sx={{ p: 2 }}>
              <List>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon>
                    <NewspaperRounded />
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.title}
                    secondary={obj.display_date || moment(obj.date).format('DD MMMM yyyy')} />
                </ListItem>
              </List>
            </CardActionArea>
          </Card>;
        })}
      </Box>}
    </Container>
  </>);
};