import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import Skeleton from '@mui/material/Skeleton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Alert from '@mui/material/Alert';
import httpClient from '../../shared/http/http-client';

export default function EnquiriesTable() {
  const [enquiriesLoading, setEnquiriesLoading] = useState(0);
  const [enquiries, setEnquiries] = useState([]);
  const [noEnquiries, setNoEnquiries] = useState(false);

  const navigate = useNavigate();

  const setSelectedRow = (enquiry) => {
    navigate(`/seller/enquiry/${enquiry.e_id}`);

  };

  useEffect(() => {
    setEnquiriesLoading(true);

    httpClient.get(`seller/enquiry`)
      .then(enquiriesRes => {
        setEnquiries([]);

        for (let enquiry of enquiriesRes.data) {
          const dateSubmittedISO = new Date(enquiry.created_at.replace(' ', 'T'));
          const dateSubmitted = dateSubmittedISO.toLocaleDateString('en-GB');
          enquiry = { ...enquiry, e_date_submitted: dateSubmitted };

          setEnquiries(enquiries => [...enquiries, { ...enquiry }]);
        }
        setEnquiriesLoading(false);
        if (enquiriesRes.data.length === 0) {
          setNoEnquiries(true);
        }
      })
      .catch(err => {
        console.log(err);
      });

  }, []);

  return (
    <>
      {noEnquiries && (
        <Alert severity="info" sx={{ p: 2, m: 8 }}>No enquiries have been received yet.</Alert>
      )}
      {!noEnquiries && (
        <Table>
          <TableBody>
            {(enquiriesLoading ? Array.from(new Array(8)) : enquiries).map((enquiry, index) => (
              enquiry ? (
                <TableRow key={index} onClick={() => setSelectedRow(enquiry)} hover={true}>
                  <TableCell>{enquiry.e_date_submitted}</TableCell>
                  <TableCell>{enquiry.project_p_id ? 'BNG Project' : 'Investment Project'}</TableCell>
                  <TableCell>{enquiry.project_p_id ? enquiry.project?.p_summary : enquiry.investment_project?.title_text}</TableCell>
                  <TableCell>{enquiry.project_p_id ? enquiry.project?.p_local_planning_authority : enquiry.investment_project?.subtitle_text}</TableCell>
                  <TableCell width='30%'>
                    <Stepper activeStep={0}>
                      <Step>
                        <StepLabel>Enquiry</StepLabel>
                      </Step>
                      <Step>
                        <StepLabel>Negotiation</StepLabel>
                      </Step>
                      <Step>
                        <StepLabel>Complete</StepLabel>
                      </Step>
                    </Stepper>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                </TableRow>
              )
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
