import { useContext, useEffect, useState } from 'react';
import { HubDetailsContext } from '../../context/hub-details';
import { Typography } from '@mui/material';

export const PrivacyPolicyLink = ({ overrideUrl, wrappingText }) => {

  const hubContext = useContext(HubDetailsContext);
  const [url, setUrl] = useState();
  const [preLinkText, setPreLinkText] = useState();
  const [postLinkText, setPostLinkText] = useState();

  useEffect(() => {
    if (hubContext) {
      setUrl(hubContext.hubDetails?.organisation_settings?.privacy_policy_link);
    }

    if (wrappingText) {
      const parts = wrappingText.split('<<PRIVACY_POLICY>>');
      if (parts[0]) {
        setPreLinkText(parts[0]);
      }

      if (parts[1]) {
        setPostLinkText(parts[1]);
      }

    }
  }, [hubContext]);

  const openFile = () => {
    window.open(overrideUrl ? overrideUrl : url, '_blank');
  };

  return (<Typography
    sx={{ display: 'inline', cursor: 'pointer' }}
    color="primary"
  >
    {preLinkText}
    <a href={overrideUrl ? overrideUrl : url} target='_blank' rel="noreferrer">Privacy Policy</a>
    {postLinkText}
  </Typography>);
};