import { Accordion, AccordionDetails, AccordionSummary, Alert, Autocomplete, Box, Button, Chip, Divider, FormControl, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import InvestmentProjectActionsMenu from '../../../seller/components/investment-projects/investment-project-actions-menu';
import SellerInvestmentProjectService from '../../../seller/services/seller-investment-project-service';
import { EditInvestmentProjectDialog } from '../../../seller/components/dialogs/edit-investment-project-dialog';
import AnswerFormDisplay from '../answer-form-display';
import { UserInformationProviderContext } from '../../context/user-information-provider';
import { ImageFullSizeDisplay } from '../image-full-size-display';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconPicker from '../icon-picker';
import { HubDetailsContext } from '../../context/hub-details';
import { HtmlParserDisplay } from '../html-parser-display/html-parser-display';
import { ProjectResourceUploadButton } from '../project-resources/project-resource-upload-button';
import { ProjectResouceList } from '../project-resources/project-resource-list';
import { UnGoalsDisplay } from './un-goals';
import AdminInvestmentProjectService from '../../../admin/services/admin-investment-project';
import { InvestmentProjectDisplayNotes } from './investment-project-display-notes';
import { Edit, EditNotificationsOutlined, EditSharp } from '@mui/icons-material';
import { CustomerServiceTextEmailLink } from '../presentation/customer-service-text-email-link';
import { DialogChangeShallowStatus } from '../dialogs/dialog-change-shallow-status';
import { useNavigate } from 'react-router-dom';
import { HubCustomButton } from '../buttons/hub-custom-button';

const InvestmentProjectBody = (props) => {

  const sellerInvestmentProjectService = new SellerInvestmentProjectService();
  const adminInvestmentProjectService = new AdminInvestmentProjectService();
  const hubContext = useContext(HubDetailsContext);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [editFormActive, setEditFormActive] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [shallowStatusDialogActive, setShallowStatusDialogActive] = useState(false);
  const [activateUpload, setActivateUpload] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const infoDialog = useContext(UserInformationProviderContext);

  const handleEditClick = () => {
    setEditFormActive(!editFormActive);
    setAnchorEl(null);
  };

  const handleUploadImagesClick = () => {
    setActivateUpload(!activateUpload);
    setAnchorEl(null);
  };

  const handleUpdateProjects = async (title_text) => {
    try {
      const body = {
        title_text: title_text
      };

      sellerInvestmentProjectService.updateInvestmentProject(props.projectDetails.id, body)
        .then((res) => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
      props.getInvestmentProject();
      handleEditClick();
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleApprovalClick = () => {
    sellerInvestmentProjectService.submitApprovalInvestmentProject(props.projectDetails.id)
      .then((res) => {
        props.getInvestmentProject();
      })
      .catch(err => {
        if (err.response?.data?.code === 4) {
          infoDialog.openDialog({
            active: true,
            title: 'Your user account is still pending',
            message: 'Your account has not yet been approved. If you would like to publish this project please contact customer support.',
            hideCancelButton: true,
            buttonCopy: 'Ok'
          })
            .then(() => {
            });
        } else if (err.response?.data?.code === 5) {
          infoDialog.openDialog({
            active: true,
            title: 'You have not completed all the forms required to publish this listing',
            message: `Please use the "Actions" button to complete the missing forms. (${err.response.data?.details.map(form => form.question_form_name)?.join(', ')})`,
            hideCancelButton: true,
            buttonCopy: 'Ok'
          })
            .then(() => {
            });
        }

      });
  };

  const handlePrimaryImageClick = (imageKey) => {
    if (props?.function === 'admin') {
      adminInvestmentProjectService.editInvestmentProject(props.projectDetails.id, { primary_image_key: imageKey })
        .then(() => {
          props.getInvestmentProject();
          infoDialog.openSnackbar({ open: true, message: 'The primary image has been successfully updated' });
        });
    } else {
      sellerInvestmentProjectService.updateInvestmentProject(props.projectDetails.id, { primary_image_key: imageKey })
        .then(() => {
          props.getInvestmentProject();
          infoDialog.openSnackbar({ open: true, message: 'The primary image has been successfully updated' });
        });
    }


  };

  const handleChangeShallowStatus = () => {
    setShallowStatusDialogActive(true);
    setAnchorEl(null);
  };

  const handleAddNewImages = () => {
    const form = new FormData();

    for (let i = 0; i < fileData.length; i++) {
      form.append("files", fileData[i]);
    }

    const requiredService = props?.function === 'admin' ? adminInvestmentProjectService : sellerInvestmentProjectService;
    requiredService.uploadNewInvestmentProjectImage(props.projectDetails.id, form)
      .then(() => {
        props.getInvestmentProject();
        setTimeout(() => {
          setFileData([]);
        }, 1000);
      });
  };

  const handleDeleteImage = (imageKey) => {
    const requiredService = props?.function === 'admin' ? adminInvestmentProjectService : sellerInvestmentProjectService;
    requiredService.deleteProjectImage(props.projectDetails.id, imageKey)
      .then(() => {
        props.getInvestmentProject();
      });
  };

  const handleShallowStatusUpdated = () => {
    props.getInvestmentProject();
    setShallowStatusDialogActive(false);
  };

  const goToEditQuestionPage = (form) => {
    console.log(form);
    navigate(`/investment-project/questions/${props.projectDetails.id}/${form.id}`);
  };

  // const toggleComingSoon = () => {
  //   adminInvestmentProjectService.toggleComingSoon(props.projectDetails.id)
  //     .then(() => {
  //       props.getInvestmentProject()
  //     })
  // }

  return (
    <Paper
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        minHeight: '80vh',
        borderRadius: '10px'
      }}
      variant='outlined'
    >

      {props.projectDetails && <ImageFullSizeDisplay
        images={props.projectDetails.investment_project_images}
        canEdit={props.projectDetails.can_edit || props?.function === 'admin'}
        primaryImageKey={props.projectDetails.primary_image_name}
        handlePrimaryImageClick={handlePrimaryImageClick}
        handleAddImage={handleAddNewImages}
        handleDeleteImage={handleDeleteImage}
        indicators={true}
        fileData={fileData}
        setFileData={setFileData}
        activateUpload={activateUpload}
        handleUploadImagesClick={handleUploadImagesClick}
        videoUrl={props.projectDetails?.video_url}
        useComparisonSlider={props.projectDetails.use_slider_image}
      >
      </ImageFullSizeDisplay>}

      <Box sx={{ p: 4, pt: 8 }}>
        <Grid container spacing={2} sx={{ pl: '6px' }}>
          {props.projectDetails?.admin_status?.status_code === 'PENDING_REVIEW' && props.function !== 'admin' &&
            < Grid item xs={12} md={12} lg={12}>
              <Alert severity="info" sx={{ mb: 4 }}>
                {hubContext.hubDetails.organisation_settings.investment_awaiting_approval_message && <HtmlParserDisplay html={hubContext.hubDetails.organisation_settings.investment_awaiting_approval_message} ></HtmlParserDisplay>}
                {!hubContext.hubDetails.organisation_settings.investment_awaiting_approval_message && 'The project is currently being reviewed, and you will be informed once it is approved or if any amendments are required.'}
              </Alert>
            </Grid>
          }

          {props.projectDetails?.admin_status?.status_code === 'APPROVED' &&
            <Grid item xs={12} md={12} lg={12}>
              <Alert severity="info" sx={{ mb: 4 }}>
                This project has been approved, but has not yet been published.
                It will be published by an administrator. If you have any questions please contact {<CustomerServiceTextEmailLink isAlert={true} />}.
              </Alert>
            </Grid>
          }

          {props.projectDetails?.admin_status?.status_code === 'AUTO_DECLINE' &&
            <Grid item xs={12} md={12} lg={12}>
              <Alert severity="error" sx={{ mb: 4 }}>
                This project has been automatically declined by the system with the following comments:<br></br>

                {props.projectDetails.answer_forms.map(form => {
                  return form.answers.filter(answer => answer.decline_message).map((answer, index) => {
                    return <Typography variant="p" color="text.primary" sx={{ fontWeight: 300, mt: 1 }} key={index}>
                      - {answer.decline_message}
                    </Typography>;
                  });
                })}

                <br></br><br></br>
                If you would like to discuss this project with a member of the team please use the 'Contact us' form.

              </Alert>
            </Grid>
          }

          <Grid item xs={12} md={4} lg={4}>
            {props.projectDetails?.admin_status.status_code !== 'AUTO_DECLINE' && <Box>
              <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                {props.projectDetails?.title_text}
              </Typography>
            </Box>}
            {props.projectDetails?.admin_status.status_code !== 'AUTO_DECLINE' && <Box>
              <Typography variant="body" color="text.primary" sx={{ mb: 3, whiteSpace: 'pre-wrap' }}>
                {props.projectDetails?.subtitle_text}
              </Typography>
            </Box>}


          </Grid>
          {props.projectDetails && props?.function !== 'admin' && props.projectDetails?.admin_status.status_code !== 'AUTO_DECLINE' &&
            <Grid item xs={12} md={8} lg={8}>
              <Stack direction='row' spacing={1} sx={{ justifyContent: 'flex-end' }}>
                {props.projectDetails?.can_edit && <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => handleApprovalClick()}
                  //startIcon={<ThumbUpOutlinedIcon />}
                  className='removeUppercase'
                >
                  Submit for Approval
                </Button>}
                <InvestmentProjectActionsMenu
                  canEdit={props.projectDetails?.can_edit}
                  handleChangeShallowStatus={handleChangeShallowStatus}
                  projectId={props.projectDetails.id}
                  handleEditClick={handleEditClick}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  handleUploadImagesClick={handleUploadImagesClick}
                />
              </Stack>
            </Grid>
          }

          {props.projectDetails && <DialogChangeShallowStatus
            projectId={props.projectDetails.id}
            statusCode={props.projectDetails.shallow_status}
            active={shallowStatusDialogActive}
            onUpdate={handleShallowStatusUpdated}
            onCancel={handleShallowStatusUpdated}
            projectType={'INVESTMENT_PROJECT'}
            isAdmin={false}>
          </DialogChangeShallowStatus>}

          {props.projectDetails && props?.function === 'admin' &&
            <Grid item xs={12} md={8} lg={8}>
              <Stack direction='row' spacing={1} sx={{ justifyContent: 'flex-end', mb: 2 }}>
                <Button
                  variant={'outlined'}
                  color={'info'}
                  disableElevation
                  className='removeUppercase'
                  startIcon={<Edit />}
                  onClick={handleEditClick}
                >
                  Edit project
                </Button>

                {/* {props.projectDetails?.admin_status?.status_code !== 'PUBLISHED' && props.projectDetails?.admin_status?.status_code !== 'CLOSED' && <Button
                  variant={props.projectDetails?.coming_soon ? 'outlined' : 'contained'}
                  color={props.projectDetails?.coming_soon ? 'error' : 'info'}
                  disableElevation
                  onClick={() => toggleComingSoon()}
                  //startIcon={<ThumbUpOutlinedIcon />}
                  className='removeUppercase'
                >
                  {props.projectDetails?.coming_soon ? 'Remove coming soon' : 'Show as coming soon'}
                </Button>} */}

                {props.projectDetails.resources.length === 0 && <ProjectResourceUploadButton
                  projectId={props.projectDetails.id}
                  projectType={'INVESTMENT_PROJECT'}
                  onSuccess={props.getInvestmentProject}
                  buttonCopy={'Upload brochure'}>
                </ProjectResourceUploadButton>}
              </Stack>

              {props.projectDetails.resources.length > 0 && <>
                <ProjectResouceList
                  resources={props.projectDetails.resources}
                  onSuccess={props.getInvestmentProject} />
              </>}
            </Grid>
          }
          <EditInvestmentProjectDialog
            editFormActive={editFormActive}
            handleEditClick={handleEditClick}
            projectDetails={props.projectDetails}
            handleUpdateProjects={handleUpdateProjects}
            setProjectDetails={props.setProjectDetails}
            updatedProjectDetails={props.updatedProjectDetails}
            setUpdatedProjectDetails={props.setUpdatedProjectDetails}
            getInvestmentProject={props.getInvestmentProject}
            isAdmin={props?.function === 'admin'}
          />
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>

            {props.projectDetails?.external_project_url && <>
              <Box>
                <Typography variant="body" color="text.primary" sx={{ fontWeight: 700, mb: 1, whiteSpace: 'pre-wrap' }}>
                  External project url:
                </Typography>
              </Box>

              <Box>
                <Typography variant="body" color="text.primary" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>
                  <a href={props.projectDetails?.external_project_url} target='_blank' rel="noreferrer">{props.projectDetails?.external_project_url}</a>
                </Typography>
              </Box>
            </>}

            <Box sx={{ pt: '15px' }}>

              {props.projectDetails?.admin_status.status_code !== 'AUTO_DECLINE' &&
                <>
                  <Typography variant="body" color="text.primary" sx={{ mb: '20px', whiteSpace: 'pre-wrap' }}>
                    {props.projectDetails?.description}
                  </Typography>

                  <Divider sx={{ mt: 6, mb: 6 }} />

                  <InvestmentProjectDisplayNotes
                    isAdmin={props?.function === 'admin'}
                    isSeller={props?.function === 'seller'}
                    investmentProject={props?.projectDetails}
                    getInvestmentProject={props.getInvestmentProject} />

                  <Divider sx={{ mt: 6, mb: 6 }} />

                  <UnGoalsDisplay isAdmin={props?.function === 'admin'} investmentProject={props?.projectDetails} getInvestmentProject={props.getInvestmentProject} />
                </>
              }

              <Divider sx={{ mt: 6, mb: 6 }} />

              {props.projectDetails?.answer_forms?.length > 0 && <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                Information provided:
              </Typography>}

              {props.projectDetails?.answer_forms?.map((form, index) => {
                return <Accordion
                  key={index}
                  expanded={expanded === `panel-${index}`}
                  onChange={handleChange(`panel-${index}`)}>

                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ alignItems: 'center', justifyContent: 'center' }}

                  >
                    <List sx={{ p: 0 }}>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemIcon>
                          <IconPicker icon="form" color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={form.question_form?.form_name} />
                      </ListItem>
                    </List>
                  </AccordionSummary>
                  <AccordionDetails>

                    {props.projectDetails.can_edit && props?.function !== 'admin' && <Button
                      variant='outlined'
                      endIcon={<EditSharp />}
                      className="removeUppercase"
                      onClick={() => goToEditQuestionPage(form)}
                      // sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}
                      sx={{ mb: 2, float: 'right' }}
                      color='info'
                    >
                      Edit answers
                    </Button>}

                    <AnswerFormDisplay key={index} suppliedAnswers={form.answers} />
                  </AccordionDetails>
                </Accordion>;
              })}

              {props.projectDetails?.hub_button && <HubCustomButton button={props.projectDetails?.hub_button}>

              </HubCustomButton>}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper >
  );
};

export default InvestmentProjectBody;