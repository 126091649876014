import { Container, Grid, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from '../../../shared/context/user-details';
import UserService from '../../../shared/services/user-service';
import { UserInformationProviderContext } from '../../../shared/context/user-information-provider';
import ThirdPartyConnectionService from '../../services/third-party-connection.service';
import { ThirdPartyConnectionsCard } from '../../components/third-party-connection-card';
import HubSpotLogo from '../../../assets/images/HubSpot_Logo.png';

export const ThirdPartyConnectionListPage = () => {

  const userDetailsContext = useContext(UserDetailsContext);
  const userService = new UserService();
  const userInformationContext = useContext(UserInformationProviderContext);
  const thirdPartyConnectionService = new ThirdPartyConnectionService();

  const [user, setUser] = useState();
  const [connections, setConnections] = useState();

  useEffect(() => {
    if (userDetailsContext.userDetails) {
      setUser(userDetailsContext.userDetails);
    }
  }, [userDetailsContext.userDetails]);

  useEffect(() => {
    if (user) {
      thirdPartyConnectionService.getAvailableConnections()
        .then(response => {
          const formatted = response.map(connection => {
            return {
              ...connection,
              authorize_uri: `${connection.authorize_uri}?client_id=${connection.client_id}&scope=${connection.scope}&redirect_uri=${connection.redirect_uri}`
            };
          });
          setConnections(formatted);
        });
    }
  }, [user]);




  return (
    <>
      <Toolbar />
      {user && <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            minHeight: '100%',
            borderRadius: '10px'
          }}
          variant='outlined'
        >

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h5" color="text.primary" gutterBottom sx={{ mb: 1 }}>
              Third party connections
            </Typography>
          </Stack>

          {!!connections && <Grid container spacing={4}>
            <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
              <ThirdPartyConnectionsCard
                isActive={!!connections[0]?.o_auth_client_connections?.length}
                title={'Hubspot'}
                image={HubSpotLogo}
                description={'Connect your account to hubspot to import new enquiries and customers directly to your HubSpot account.'}
                link={connections[0]?.authorize_uri} />
            </Grid>

          </Grid>}


        </Paper>
      </Container>}
    </>
  );
};