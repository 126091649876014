import React, { useContext, useEffect, useState } from "react";
import Counter from '../../shared/components/dashboard/counter';
import ProjectService from '../services/project-service';
import { Copyright } from '../../shared/components/copyright';
import EnquiryService from '../services/enquiry-service';
import { Toolbar, Stack, Container, Grid, Paper } from '@mui/material';
import DashboardButton from '../../shared/components/dashboard/button';
import DashboardProjectsTable from '../components/dashboard/projects-table';
import DashboardInvestmentProjectsTable from '../components/dashboard/investment-projects-table';
import PieChartWidget from '../../shared/components/dashboard/pie-chart';
import Title from '../../shared/components/title';
import { HubDetailsContext } from '../../shared/context/hub-details';
import { UserDetailsContext } from '../../shared/context/user-details';
import { EcologistDashboard } from '../../ecologists/components/ecologist-dashboard';
import { UserInfoTopDisplay } from '../../ecologists/components/user-info-top-display';
import SellerDashboardService from '../services/dashboard-service';

const DashboardContent = (props) => {

  const sellerDashboardService = new SellerDashboardService();
  const hubContext = useContext(HubDetailsContext);
  const userDetailsContext = useContext(UserDetailsContext);

  const [moduleCodes, setModuleCodes] = useState([]);
  const [isEcologist, setIsEcologist] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [isMainHub, setIsMainHub] = useState(false);

  const [dashboardInformation, setDashboardInformation] = useState();

  useEffect(() => {
    if (hubContext.hubDetails) {
      console.log(hubContext);
      setModuleCodes([]);
      hubContext.hubDetails.hubTiles?.forEach(tile => {
        const codes = moduleCodes;
        codes.push(tile?.tile_code);
        setModuleCodes(codes);
      });
      setIsMainHub(hubContext.hubDetails.main_hub);
    }
  }, [hubContext.hubDetails]);

  useEffect(() => {
    if (userDetailsContext.userDetails) {
      setIsEcologist(userDetailsContext.userDetails.roles.includes('ECOLOGIST'));
      setUserDetails(userDetailsContext.userDetails);
    }
  }, [userDetailsContext]);

  useEffect(() => {
    sellerDashboardService.getDashboardSummary()
      .then(response => {
        console.log(response);
        setDashboardInformation(response);
      });
  }, []);


  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <>
      <Toolbar />
      <UserInfoTopDisplay subTitle={!userDetails?.roles.includes('SELLER') ? 'View all your managed listings' : undefined} containerMaxWidth={isMainHub ? 'xl' : 'lg'}>

        {/* {userDetails?.roles.includes('SELLER') && <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}> */}
        {userDetails?.roles.includes('SELLER') && isMainHub && dashboardInformation && <Container maxWidth="xl">
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={4} lg={4} direction={'column'} justifyContent={'space-between'} >
              <Stack spacing={1} direction={'column'} justifyContent={'space-between'} sx={{ height: '100%' }}>
                <DashboardButton
                  title='New Listing'
                  linkTo='/projects/new'
                  icon='addBoxOutlined'
                />
                {moduleCodes.includes('INVESTMENT_PROJECT') ?
                  <DashboardButton
                    title='New Project for Funding'
                    linkTo='/investment-project/new'
                    icon='addBoxOutlined'
                  />
                  :
                  <DashboardButton
                    title='Go to Marketplace'
                    linkTo='/'
                    icon='openInNew'
                  />
                }
              </Stack>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Counter
                title='Enquiries'
                value={dashboardInformation.enquiries}
                viewLabel='enquiries'
                type='number'
                linkTo='/deals'
                noDate
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Counter
                title='Units For Sale'
                value={dashboardInformation.units_for_sale?.toFixed(2)}
                viewLabel='projects'
                type='number'
                linkTo='/projects'
                noDate
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Counter
                title='Units Sold'
                value={dashboardInformation.units_sold}
                viewLabel='sold units'
                type='number'
                linkTo='/sales'
                noDate
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              {moduleCodes.includes('INVESTMENT_PROJECT') ?
                <Counter
                  title='Pledges'
                  value={0}
                  viewLabel='pledges'
                  type='number'
                  noLink
                  noDate
                />
                : <Counter
                  title='Registered Buyers'
                  value={dashboardInformation.registered_buyers}
                  viewLabel='registered buyers'
                  type='number'
                  noLink
                  noDate
                />
              }
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '10px', flexGrow: 1, height: '100%' }} elevation={0}>
                <Title>Listings By Status</Title>
                <PieChartWidget data={
                  [{
                    name: "Published",
                    value: dashboardInformation.statuses?.published
                  },
                  {
                    name: "Draft",
                    value: dashboardInformation.statuses?.draft
                  },
                  {
                    name: "Pending Approval",
                    value: dashboardInformation.statuses?.pending_approval
                  }
                  ]
                } />
              </Paper></Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '10px', flexGrow: 1, height: '100%' }} elevation={0}>
                <Title>Listings By Type</Title>
                <PieChartWidget data={
                  [{
                    name: "Area",
                    value: parseFloat(dashboardInformation.area_units)
                  },
                  {
                    name: "Watercourse",
                    value: parseFloat(dashboardInformation.watercourse_units)
                  },
                  {
                    name: "Hedgerow",
                    value: parseFloat(dashboardInformation.hedgerow_units)
                  }]
                } />
              </Paper></Grid>
            <Grid item xs={12} md={4} lg={4} >
              <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '10px', mb: 3, flexGrow: 1, height: '100%' }} elevation={0}>
                {moduleCodes.includes('INVESTMENT_PROJECT') ? <DashboardInvestmentProjectsTable /> : <DashboardProjectsTable />}
              </Paper>
            </Grid>
          </Grid>
          {/* <Copyright sx={{ pt: 4 }} /> */}
        </Container >}

        {userDetails?.roles.includes('SELLER') && !isMainHub && dashboardInformation && <Container maxWidth="lg">
          <Grid container spacing={3} sx={{ mb: 2 }}>
            {hubContext?.investmentModuleActive && <Grid item xs={12} md={4} lg={4}>
              <DashboardButton
                title='Submit a New Project for Funding'
                subtitle='Submit a nature project to be listed on the exchange'
                linkTo='/investment-project/new'
                icon='addBoxOutlined'
                size='large'
                alignTop={true}
              />
            </Grid>}
            <Grid item xs={12} md={hubContext.investmentModuleActive ? 4 : 6} lg={hubContext.investmentModuleActive ? 4 : 6}>
              <DashboardButton
                title='Sell your Natural Capital Units'
                subtitle='Submit a unit-based project to be listed on the exchange. e.g. BNG, carbon, nutrient neutrality units etc.'
                linkTo='/projects/new'
                icon='spaOutlined'
                size='large'
                alignTop={true}
              />
            </Grid>
            <Grid item xs={12} md={hubContext.investmentModuleActive ? 4 : 6} lg={hubContext.investmentModuleActive ? 4 : 6}>
              <DashboardButton
                title='Learning Hub'
                subtitle='Click here to go to the home page and see how buyers view projects.'
                linkTo='/hub/learning'
                icon='article'
                size='large'
                alignTop={true}
              />
            </Grid>
            {hubContext.investmentModuleActive &&
              <Grid item xs={12} md={12} lg={12} >
                <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '10px', mb: 3, flexGrow: 1, height: '100%' }} elevation={0}>
                  <DashboardInvestmentProjectsTable />
                </Paper>
              </Grid>
            }
            <Grid item xs={12} md={12} lg={12} >
              {dashboardInformation.last_projects &&
                <Paper sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '10px',
                  mb: 3,
                  flexGrow: 1,
                  height: '100%',

                }} elevation={0}>
                  <DashboardProjectsTable />
                </Paper>
              }
            </Grid>
          </Grid>
        </Container>}

        {isEcologist && isMainHub && <>
          <Container maxWidth="xl">
            <EcologistDashboard />
          </Container>
        </>}
        <Copyright sx={{ pt: 4 }} />
      </UserInfoTopDisplay>
    </>
  );
};

export default DashboardContent;