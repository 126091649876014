import React from "react";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const HubCustomButton = ({ button, buttonId }) => {

  return (<>
    {button && <Button
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      component={Link}
      to={button.url}
      sx={{ width: '80%', mt: 2, mb: 2 }}
      target='_self'
      className='removeUppercase'
      rel="noopener noreferrer"
    >
      {button.title_copy}
    </Button>
    }
  </>
  );
};