import httpClient from '../../shared/http/http-client';

export default class ThirdPartyConnectionService {
  http;

  constructor() {
    this.http = httpClient;
  }

  async getAvailableConnections() {
    return this.http.get(`seller/third-party-connection`)
      .then(response => {
        return response.data;
      });
  }

  async submitAuthCode(provider, code) {
    return this.http.post(`seller/third-party-connection/auth-code`, {provider: provider.toUpperCase(), code: code})
      .then(response => {
        return response.data;
      });
  }

}